// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("dropzone")
require("timezone")
require("dismissable-message")
require("documents")
require("password-toggle")
require("neutral/decision")
require("neutral/scheduling")
require("notifications")
require("form-utils")
require("messages")
require("timeline-utils")
require("html2canvas")
require("support-utils")
require("fomantic-ui/dist/components/accordion")
require("fomantic-ui/dist/components/transition")
require("fomantic-ui/dist/components/dropdown")
require("fomantic-ui/dist/components/popup")
require("fomantic-ui/dist/components/calendar")
require("fomantic-ui/dist/components/checkbox")
require("fomantic-ui/dist/components/modal")
require("fomantic-ui/dist/components/dimmer")
require("fomantic-ui/dist/components/sidebar")
require("fomantic-ui/dist/components/form")

import '../../assets/stylesheets/dropzone.css'
import '../../assets/stylesheets/application.scss'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../../assets/images', true)
const imagePath = (name) => images(name, true)

// Google Analytics: Turbolinks configuration
// $(document).on("turbolinks:load", function() {
//   gtag('config', gon.ga_measurement_id, {'page_location': event.data.url});
// })

$(function() {
  var choosingbar = function( event ){
    $(event.target).parents(".counting").children(".counter").children(".count").text($(event.target).val().length);
  };
  $(".counting textarea").keyup(choosingbar).change(choosingbar).focus(choosingbar);
})

window.jQuery = $;

var initializeDropdown = function() {
  $('.ui.dropdown, .ui.form select').dropdown()
  $('.ui.menu .ui.dropdown').dropdown({
    on: 'hover'
  })
}

import { initializePayments } from 'payment';
import {
  displayAjaxResult,
  clearAjaxResult,
  initializeCalendars,
} from 'form-utils';

$(function() {
  initializeDropdown();
  initializeCalendars();
  var checkbox = $('.ui.checkbox')
  if (!checkbox.hasClass("validate-as-radio")) {
    checkbox.checkbox();
  }
});

$(function() {
  $('#pop_modal').on('click', function(){
    $("#modal").modal('show');
  })
})
$(function() {
  $('.ui.accordion').accordion();
})

// works with multiple forms on a page (need unique ids)
// no manual managing "#" for modal id
// no expected UI structure (like .main_segment)
$(function() {
  $( document ).ready(function() {
    document.querySelectorAll(".popup_modal").forEach(button => {
      button.addEventListener('click', () => {
        var modal_id = $(button).data("modal-to-show");
        $(`#${modal_id}`).modal("show");
        initializeCalendars();
      })
    })
  })
})

$(function() {
  $(document).ready(function() {
    if ($('#uncloseable_modal').length) {
      $("#uncloseable_modal").modal('setting', 'closable', false).modal('show');
    }
  })
})

$(function() {
  $(".main-segment").on("click", ".pop_modal", function(){
    var modal = $(this).data("modal-to-show");
    $(modal).modal("show");
    initializeCalendars();
  })
})

$(function() {
  $('.pop_specific_modal').on('click', function(){
    var modal = $(this).data("modal-to-show");
    $(modal).modal("show");
  })
})

$(function() {
  // autosubmit filters
  if ($('#filters').length > 0) {
    $('#filters select').change(function() {
      $(this).parents('form').submit()
    })
  }
})

// disable save until textarea changed
$(function() {
  var $textarea = $('textarea.disable-save');
  if ($textarea.length > 0) {
    $textarea.on('keyup', function() {
      $textarea.parents('form').find('input[type="submit"]').removeAttr('disabled')
    })
  }
})

function smoothScrollTo(target, duration) {
    const startPosition = window.pageYOffset;
    const targetPosition = target.getBoundingClientRect().top + window.pageYOffset - (window.innerHeight / 2 - target.getBoundingClientRect().height / 2);
    const distance = targetPosition - startPosition;
    let startTime = null;

    function animation(currentTime) {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const run = easeInOutQuad(timeElapsed, startPosition, distance, duration);
      window.scrollTo(0, run);
      if (timeElapsed < duration) requestAnimationFrame(animation);
    }

    function easeInOutQuad(t, b, c, d) {
      t /= d / 2;
      if (t < 1) return c / 2 * t * t + b;
      t--;
      return -c / 2 * (t * (t - 2) - 1) + b;
    }

    requestAnimationFrame(animation);
  }

window.Newera = {
  initializeDropdown: initializeDropdown,
  initializePayments: initializePayments,
  displayAjaxResult: displayAjaxResult,
  clearAjaxResult: clearAjaxResult,
  smoothScrollTo: smoothScrollTo,
}
